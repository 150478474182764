import Image from "next/image";

import styled from "@emotion/styled";
import { List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";

import { Queens } from "@styles/import-fonts";

export const SWrapper = styled(Stack)`
  position: relative;
  background: #e1e4fa;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100px;
    border-radius: 0 0 50% 50%;
  }

  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
  }

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-of-type(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-of-type(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-of-type(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-of-type(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }

  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
    .content {
      height: 30vh;
    }
    h1 {
      font-size: 24px;
    }
  }
`;

export const SImage = styled(Image)`
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.up("md")} {
    position: static;
    transform: none;
  }
`;

export const STitle = styled(Typography)`
  color: #4a4f8d;
  font-size: 33px;
  line-height: 1.3;
  font-weight: 400;

  b {
    font-weight: 700;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: #fc6d1d;
    text-decoration-thickness: 4px;
    text-underline-offset: 8px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 44px;
  }
`;

export const SList = styled(List)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const SListItemIcon = styled(ListItemIcon)`
  background: currentColor;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SListItem = styled(ListItem)`
  position: relative;
  background: #fff;
  border: 1px solid #767ee220;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  min-height: 170px;
  align-items: flex-start;
  width: 80%;
  padding-bottom: 40px;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid #767ee250;
    z-index: 10;
    background: #fbfcff;
  }

  .MuiListItemText-primary {
    color: currentColor;
  }

  ${SListItemIcon} {
    color: currentColor;
  }

  &:nth-of-type(2) {
    top: -20px;
    margin-left: auto;
  }

  &:nth-of-type(3) {
    top: -30px;
  }
`;

export const SListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    color: #767ee2;
    font-size: 25px;
    font-family: ${Queens.style.fontFamily};
    margin-bottom: 8px;
    font-weight: 600;
  }

  .MuiListItemText-primary {
    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: 23px;
    }
  }

  .MuiListItemText-secondary {
    color: #4e3b30;
    font-size: 16px;
  }
`;
