import { SvgIcon } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

type LogoGoogleProps = {
  sx?: SxProps<Theme>;
};

export const LogoGoogle = ({ sx }: LogoGoogleProps) => {
  return (
    <SvgIcon sx={sx}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6 10.2273C19.6 9.51825 19.5364 8.83643 19.4182 8.18188H10V12.0501H15.3818C15.15 13.3001 14.4455 14.3592 13.3864 15.0682V17.5773H16.6182C18.5091 15.8364 19.6 13.2728 19.6 10.2273Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.9999C12.7 19.9999 14.9636 19.1044 16.6182 17.5772L13.3864 15.0681C12.4909 15.6681 11.3455 16.0226 10 16.0226C7.39546 16.0226 5.19092 14.2635 4.40455 11.8999H1.06364V14.4908C2.7091 17.759 6.09092 19.9999 10 19.9999Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40455 11.8999C4.20455 11.2999 4.09091 10.659 4.09091 9.99994C4.09091 9.34085 4.20455 8.69994 4.40455 8.09994V5.50903H1.06364C0.386364 6.85903 0 8.38631 0 9.99994C0 11.6136 0.386364 13.1409 1.06364 14.4909L4.40455 11.8999Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.97727C11.4682 3.97727 12.7864 4.48182 13.8227 5.47273L16.6909 2.60455C14.9591 0.990909 12.6955 0 10 0C6.09092 0 2.7091 2.24091 1.06364 5.50909L4.40455 8.1C5.19092 5.73636 7.39546 3.97727 10 3.97727Z"
        fill="#EA4335"
      />
    </SvgIcon>
  );
};
