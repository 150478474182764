import { Box, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SContainer = styled(Stack)`
  align-items: center;
  width: 553px;
  padding: ${({ theme }) => theme.spacing(5)};
  text-align: center;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

export const STitle = styled(Typography)<{ component: unknown }>`
  font-size: ${({ theme }) => theme.typography.pxToRem(34)};
  font-weight: 500;
  color: #000;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  }
`;

export const SSubtitle = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.16px;
  padding-top: ${({ theme }) => theme.spacing(4.5)};
  padding-bottom: ${({ theme }) => theme.spacing(4.5)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    letter-spacing: 0.14px;
  }
`;

export const SButton = styled(Button, {
  shouldForwardProp: prop => prop !== "isInVideoOverlay",
})<{ component?: unknown; isInVideoOverlay?: boolean }>(
  ({ theme, isInVideoOverlay }) => `
  width: ${isInVideoOverlay ? "212px" : "357px"};
  font-size: ${isInVideoOverlay ? "12px" : "16px"};
  font-weight: 600;
  line-height: 140%;
  letter-spacing: ${isInVideoOverlay ? "0.12px" : "0.16px"};
  border-color: #000;
  color: #000;
  background-color: #fff;
  border-radius: 51px;
  padding: ${isInVideoOverlay ? theme.spacing(1.5) : theme.spacing(2, 3)};
  margin-bottom: ${theme.spacing(1.5)};
`
);

export const SLogin = styled(Typography, {
  shouldForwardProp: prop => prop !== "isInVideoOverlay",
})<{ isInVideoOverlay?: boolean }>(
  ({ theme, isInVideoOverlay }) => `
  color: ${isInVideoOverlay ? "#fff" : "#000"};
  font-size: ${theme.typography.pxToRem(16)};
  letter-spacing: 0.16px;
  line-height: 140%;
  margin-top: ${theme.spacing(4)};
`
);

export const SLoginButton = styled(Button)`
  color: inherit;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0.16px;
  line-height: 140%;
  text-decoration: underline;
  min-width: 0;
  vertical-align: baseline;
  padding: 0;
`;

export const SFade = styled(Box)`
  background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
  width: 100%;
  position: absolute;
`;

export const SLoggedOutOverlay = styled(Stack)`
  position: absolute;
  width: 1114px;
  height: 424px;
  align-items: center;
  justify-content: center;
  z-index: 2;

  ${({ theme }) => theme.breakpoints.down("md")} {
    top: -110px;
  }
`;

export const SLoggedOutMessage = styled(Typography)<{ component: unknown }>`
  color: #fff;
  font-size: ${({ theme }) => theme.typography.pxToRem(34)};
  font-weight: 500;
  line-height: 100%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    width: 70%;
    text-align: center;
  }
`;
