import Image from "next/image";

import { Container, Divider, Stack } from "@mui/material";

import PopupRequestDemo from "@components/Homepage/PopupRequestDemo";

import usePopup from "@hooks/usePopup";

import {
  SButton,
  SChart,
  SChartItem,
  SChartWrapper,
  SInnerCircle,
  SOuterCircle,
  STitle,
  SWrapper,
} from "./styles";

const FourthFold = () => {
  const { handleOpenPopup } = usePopup();

  const handleRequestADemo = () => {
    handleOpenPopup<{ onClose: () => void }>({ component: PopupRequestDemo });
  };

  return (
    <SWrapper>
      <Container>
        <Divider sx={{ borderColor: "#E1E4FA", mb: 10 }} />
        <Stack
          alignItems="center"
          py={{ xs: 0, md: 12 }}
          spacing={{ xs: 4, md: 15 }}
          justifyContent="space-between"
          direction={{ xs: "column", sm: "row" }}
        >
          <Stack spacing={4} alignItems="flex-start" flex={{ md: "1 1 0", xs: "0 0 100%" }}>
            <STitle variant="title">Assemble supports your entire nursing organization</STitle>
            <SButton variant="contained" fullWidth onClick={handleRequestADemo}>
              Request a demo
            </SButton>
          </Stack>

          <Stack alignItems="flex-start" flex={{ md: "1 1 0", xs: "0 0 100%" }}>
            <SChart>
              <SChartWrapper>
                <SOuterCircle>
                  <SChartItem>
                    <span>Personalized Development</span>
                  </SChartItem>
                  <SChartItem>
                    <span>Scalable Coaching</span>
                  </SChartItem>
                  <SChartItem>
                    <span>Certification Prep</span>
                  </SChartItem>
                  <SChartItem>
                    <span>People Analytics</span>
                  </SChartItem>
                  <SChartItem>
                    <span>Annual Reviews</span>
                  </SChartItem>
                  <SChartItem>
                    <span>Succession Planning</span>
                  </SChartItem>
                </SOuterCircle>

                <SInnerCircle>
                  <Image
                    src="/images/come-assemble-logo-white.svg"
                    priority
                    alt="Come Assemble Logo"
                    width={150}
                    height={40}
                  />
                </SInnerCircle>
              </SChartWrapper>
            </SChart>
          </Stack>
        </Stack>
        <Divider sx={{ borderColor: "#E1E4FA", mt: 7 }} />
      </Container>
    </SWrapper>
  );
};

export default FourthFold;
