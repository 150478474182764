import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";

import FieldsComponent, { FieldType } from "@components/common/Form/RHFFieldSelector";
import Popup from "@components/common/Popup";
import SuccessMessagePopup from "@components/common/SuccessMessagePopup";

import useTransactionalEmail from "@hooks/useTransactionalEmail";

import { FormValues, fields, getMappedFields, validateSchema } from "./helper";

type PopupRequestDemoProps = {
  onClose: () => void;
};

const PopupRequestDemo = ({ onClose }: PopupRequestDemoProps) => {
  const { onSendEmail, showSuccessPage } = useTransactionalEmail();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: fields.map(({ name }) => ({ [name]: "" })),
  });

  const onSubmit = async (values: FormValues) => {
    try {
      const content = getMappedFields(values);

      await onSendEmail({
        content,
        from: { email: values.email, name: values.firstName },
        subject: "Request a demo - New register",
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Popup onClose={onClose}>
      {showSuccessPage ? (
        <SuccessMessagePopup onClose={onClose} />
      ) : (
        <Stack direction="column" spacing={3} alignItems="flex-start">
          <Typography variant="title" sx={{ fontSize: 40 }} fontWeight={400}>
            Request a demo
          </Typography>

          <Box component="form" width="100%">
            <Stack display={{ lg: "grid", xs: "flex" }} gridTemplateColumns="1fr 1fr" gap="20px">
              {fields.map(({ name, fieldType, label, ...rest }) => (
                <FieldsComponent
                  key={name}
                  id={name}
                  label={label}
                  fieldType={fieldType as FieldType}
                  control={control}
                  required
                  {...rest}
                />
              ))}
            </Stack>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", pb: 2 }}>
            <LoadingButton
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              loadingIndicator="Sending..."
              variant="contained"
            >
              Request a demo
            </LoadingButton>
          </Box>
        </Stack>
      )}
    </Popup>
  );
};

export default PopupRequestDemo;
