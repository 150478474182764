import Image from "next/image";

import styled from "@emotion/styled";
import { Chip, Typography } from "@mui/material";

export const STitle = styled(Typography)`
  color: #fc6d1d;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 40px;
    line-height: 60px;
  }

  span {
    display: block;
    color: #4e3b30;
    font-weight: bold;

    ${({ theme }) => theme.breakpoints.up("md")} {
      font-size: 55px;
    }
  }
`;

export const SImage = styled(Image)`
  position: absolute;
  right: 0;
  object-fit: contain;
  width: 45%;
  height: auto;

  ${({ theme }) => theme.breakpoints.down("md")} {
    top: 0;
    width: 90%;
  }
`;

export const SChip = styled(Chip)`
  background: #fc6d1d;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
`;
