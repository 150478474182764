type ShareLinkProps = {
  title: string;
  text: string;
  url: string;
  callback?: () => void;
};

/**
 * Shares a link using the Web Share API.
 * @param {ShareLinkProps} options - The options for sharing the link.
 * @param {string} options.url - The URL to be shared.
 * @param {string} options.title - The title of the shared content.
 * @param {string} options.text - The text of the shared content.
 * @param {Function} options.callback - The callback function to be called after sharing.
 * @returns {Promise<any>} - A promise that resolves with the result of the sharing operation or rejects with an error.
 */
export async function shareLink({ url, title, text, callback }: ShareLinkProps) {
  if (!navigator.canShare) {
    return callback?.();
  }

  try {
    return await navigator.share({ url, title, text });
  } catch (error) {
    console.log("Error on sharing content", error);
    return error;
  }
}

/**
 * Copies the provided URL to the clipboard.
 * @param url The URL to be copied.
 * @returns A promise that resolves to an object with success and message properties.
 */

export async function copyToClipboard(url: string) {
  try {
    await navigator.clipboard.writeText(url);

    return { success: true, message: "Link copied to clipboard" };
  } catch (err) {
    return { success: false, message: `Error on copying link to clipboard: ${err}` };
  }
}
