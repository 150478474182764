import Image from "next/image";

import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const SImage = styled(Image)`
  width: 115px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 155px;
  }
`;

export const SLeftColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const SHamburgerMenu = styled("button")`
  border: 0;
  padding: 0;
  background: transparent;
  margin-right: 15px;
  font-size: 30px;
  color: #4e3b30;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;

export const SLoginButton = styled(Button)<{ component?: unknown }>`
  cursor: pointer;
  min-height: auto;
  padding: 7px 20px;
  font-weight: 600;
  border: 4px solid #fff0e8;
  font-size: 15px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 10px 30px;
    font-size: 16px;
  }
`;
