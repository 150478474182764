import Head from "next/head";
import { ReactNode } from "react";

import { assemble } from "@/configs/assemble";

type PageWrapperProps = {
  children: ReactNode;
  title?: string;
  prefixTitle?: string;
  description?: string;
  featuredImage?: string;
  restHead?: ReactNode;
  customMetaTags?: {
    noFollowNoIndex?: string;
    canonicalUrl?: string;
  };
};

const PageWrapper = ({
  children,
  prefixTitle,
  description = assemble.description,
  featuredImage = assemble.featuredImage,
  restHead = null,
  title: customTitle,
  customMetaTags,
}: PageWrapperProps) => {
  const title = customTitle ?? [prefixTitle, assemble.pageTitle].join(" - ");
  const metaTags = getCustomMetaTags(customMetaTags);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="community" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={featuredImage} />
        <meta name="twitter:card" content={featuredImage} />
        {restHead}
        {metaTags}
      </Head>

      {children}
    </>
  );
};

export default PageWrapper;

// ======================================

function getCustomMetaTags(tags: PageWrapperProps["customMetaTags"]) {
  const canonical = getCanonical(tags?.canonicalUrl);
  const robots = getNoFollowNoIndex(tags?.noFollowNoIndex);

  return (
    <>
      {canonical}
      {robots}
    </>
  );
}

function getCanonical(canonicalUrl?: string) {
  if (canonicalUrl) {
    return <link rel="canonical" href={canonicalUrl} />;
  }
}

function getNoFollowNoIndex(noFollowNoIndex?: string) {
  if (noFollowNoIndex) {
    return <meta name="robots" content={noFollowNoIndex} />;
  }
}
