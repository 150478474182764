import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { Container, Stack, Typography } from "@mui/material";

import { SImage, SList, SListItem, SListItemIcon, SListItemText, STitle, SWrapper } from "./styles";

const SecondFold = () => {
  return (
    <SWrapper pt={10}>
      <Container>
        <Stack
          direction={{ md: "row", xs: "column" }}
          position="relative"
          top={{ xs: "-50px", md: "-30px" }}
          zIndex={1}
          spacing={{ md: 10, xs: 2 }}
        >
          <Stack spacing={2} sx={{ flex: "1 1 0" }}>
            <SImage src="/images/home/image.png" alt="Second Fold" width="224" height="189" />

            <STitle variant="title">
              One intelligence platform to meet the <b>professional development needs</b> of your
              entire nursing organization
            </STitle>

            <Typography fontSize={{ xs: 16, md: 18 }}>
              Assemble scales professional development across your nursing organization to increase
              retention in health systems.
            </Typography>
          </Stack>

          <Stack alignItems="flex-start" sx={{ flex: "1 1 0", position: "relative", top: 20 }}>
            <SList>
              <SListItem sx={{ color: "#767ee2" }}>
                <SListItemIcon>
                  <SubdirectoryArrowRightIcon sx={{ color: "#fff" }} />
                </SListItemIcon>
                <SListItemText
                  primary="Learn what you need"
                  secondary="Assess individual knowledge and skill gaps. Tailor skill building and professional development to individual needs."
                />
              </SListItem>
              <SListItem sx={{ color: "#fc6d1d" }}>
                <SListItemIcon>
                  <SubdirectoryArrowRightIcon sx={{ color: "#fff" }} />
                </SListItemIcon>
                <SListItemText
                  primary="Unlock opportunities"
                  secondary="Assist with succession planning. Unlock growth opportunities."
                />
              </SListItem>
              <SListItem sx={{ color: "#79cb86" }}>
                <SListItemIcon>
                  <SubdirectoryArrowRightIcon sx={{ color: "#fff" }} />
                </SListItemIcon>
                <SListItemText
                  primary="Understand your team"
                  secondary="Insights to better understand your team. Analytics to focus your efforts and resources where needed."
                />
              </SListItem>
            </SList>
          </Stack>
        </Stack>
      </Container>

      <div>
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </div>
    </SWrapper>
  );
};

export default SecondFold;
