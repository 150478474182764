import Image from "next/image";

import { Box, Container, Stack } from "@mui/material";

import PopupRequestDemo from "@components/Homepage/PopupRequestDemo";

import usePopup from "@hooks/usePopup";

import { SButton, SDescription, STitle } from "./styles";

const FirstFold = () => {
  const { handleOpenPopup } = usePopup();

  const handleRequestADemo = () => {
    handleOpenPopup<{ onClose: () => void }>({ component: PopupRequestDemo });
  };

  return (
    <Stack>
      <Container>
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent="space-between"
          spacing={{ xs: 2, md: 8 }}
        >
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems={{ xs: "normal", md: "flex-start" }}
            flex={{ md: "1 1 0", xs: "0 0 100%" }}
          >
            <STitle variant="title">
              Develop your <span>frontline.</span> Improve your <span>bottom line.</span>
            </STitle>
            <SDescription>
              The Assemble platform transforms intelligence into personalized development and
              strategic workforce planning for your entire nursing organization.
            </SDescription>

            <Box pt={3}>
              <SButton variant="contained" fullWidth onClick={handleRequestADemo}>
                Request a demo
              </SButton>
            </Box>
          </Stack>
          <Image
            width={725}
            height={546}
            quality={100}
            alt="Come Assemble"
            src="/images/home/home-score-10.png"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Stack>
      </Container>
    </Stack>
  );
};

export default FirstFold;
