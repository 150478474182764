import { keyframes } from "@emotion/react";

import styled from "@emotion/styled";
import { Box, Button, Stack, Typography } from "@mui/material";

import { secondary } from "@styles/colors";

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const pulse = keyframes`
  100% {
    box-shadow: rgba(252, 109, 29, 0) 0px 0px 0px 14px;
  }
`;

export const SWrapper = styled(Stack)`
  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    transform: translateX(30%);
    z-index: -1;
    background: url("/images/home/support-ellipse.png") no-repeat;
    width: 310px;
    height: 310px;
  }

  &:after {
    left: 0;
    bottom: 0;
    transform: translateX(-30%);
  }
`;

export const STitle = styled(Typography)`
  color: #767ee2;
  font-size: 33px;
  font-weight: 600;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 45px;
    padding-top: 50px;
  }
`;

export const SButton = styled(Button)`
  font-size: 16px;
  width: 100%;
  box-shadow: rgba(252, 109, 29, 1) 0px 0px 0px 0px;
  animation: 2s ease 0s infinite normal none running ${pulse};

  &:hover {
    animation: none;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-weight: 700;
    font-size: 20px;
    padding: 15px 50px;
    width: auto;
  }
`;

export const SChart = styled(Box)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 400px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 1 1 0;
    height: 560px;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }
`;

export const SChartWrapper = styled(Box)`
  position: relative;

  ${({ theme }) => theme.breakpoints.up("md")} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const SOuterCircle = styled(Box)`
  position: relative;
  height: 350px;
  width: 350px;
  border: 2px solid ${secondary[10]};
  border-radius: 50%;
  transform-origin: center center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    animation: ${rotate} 80s linear infinite;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 270px;
    width: 270px;
  }
`;

export const SInnerCircle = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 250px;
  width: 250px;
  border-radius: 50%;
  background: #fc6d1e;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 200px;
    width: 200px;
  }
`;

export const SChartItem = styled(Box)`
  position: absolute;
  border-radius: 50%;
  border: 4px solid #fff;
  outline: 2px solid ${secondary[10]};
  background: #fff;
  width: 30px;
  height: 30px;
  background: #a59d98;

  span {
    text-align: center;
    font-size: 14px;
    color: ${secondary[90]};
    position: absolute;

    ${({ theme }) => theme.breakpoints.up("md")} {
      animation: ${rotate} 80s linear infinite;
      animation-direction: reverse;
    }
  }

  &:nth-of-type(1) {
    top: -15.05px;
    left: 173.54px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      top: -15px;
      left: 123.54px;
    }

    span {
      top: -50px;
      left: -60px;
    }
  }

  &:nth-of-type(2) {
    top: 48px;
    left: 294.71px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      left: 234.71px;
    }

    span {
      top: -30px;
      left: 35px;
    }
  }

  &:nth-of-type(3) {
    top: 208px;
    left: 329.53px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      left: 219.53px;
    }

    span {
      top: -35px;
      left: 35px;
    }
  }

  &:nth-of-type(4) {
    top: 324.93px;
    left: 219.72px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      top: 254.93px;
      left: 119.72px;
    }

    span {
      top: 30px;
      left: 20px;
    }
  }

  &:nth-of-type(5) {
    top: 270.08px;
    left: 25.73px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      top: 200.08px;
      left: 10.73px;
    }

    span {
      top: 30px;
      left: -60px;
    }
  }

  &:nth-of-type(6) {
    top: 104.22px;
    left: -3.26px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      top: 54.22px;
      left: -3.26px;
    }

    span {
      top: -45px;
      left: -80px;
    }
  }
`;
