import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { useState } from "react";

import * as yup from "yup";

import { LoadingButton } from "@mui/lab";
import { Alert, Button, Divider, Stack, Typography } from "@mui/material";

import { CopyLinkPrompt } from "@components/common/CreateAccountPrompt/CopyLinkPrompt";
import FieldsComponent from "@components/common/Form/RHFFieldSelector";
import { LogoGoogle } from "@components/icons/logo.google";

import { signinSelectors } from "@configs/features/testSelectors";

import { isInstagramUserAgent } from "@helpers/userAgent";
import { emailValidator, passwordValidator } from "@helpers/validators/custom.validators";
import useForm from "@hooks/useForm";
import { secondary } from "@styles/colors";

const validationSchema = yup
  .object()
  .shape({ email: emailValidator, password: passwordValidator })
  .required();

type FormValues = yup.InferType<typeof validationSchema>;

export const LoginComponent = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { push, asPath } = useRouter();

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { control, handleSubmit } = useForm<FormValues>(validationSchema, {
    defaultValues: { email: "", password: "" },
  });

  const handleFormSubmit = async ({ email, password }: FormValues) => {
    setError("");
    setLoading(true);

    try {
      const response = await signIn("credentials", { username: email, password, redirect: false });

      if (!response?.ok) {
        throw new Error(response?.error ?? "Something went wrong");
      }

      if (asPath === "/") {
        push("/dashboard");
      }

      onSuccess?.();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    onSuccess?.();
    push("/auth/forgot-password");
  };

  const handleSignInWithGoogle = () => signIn("google", { callbackUrl: "/dashboard" });

  const isInstagram = isInstagramUserAgent();

  return (
    <Stack direction="column" spacing={3} sx={{ pb: 4 }}>
      <Typography variant="title" fontSize={{ lg: 34, xs: 28 }}>
        Log In
      </Typography>

      {isInstagram ? (
        <CopyLinkPrompt />
      ) : (
        <Stack direction="column" spacing={2}>
          <FieldsComponent
            id="email"
            label="Email"
            fieldType="input"
            control={control}
            data-testid={signinSelectors.email}
          />
          <FieldsComponent
            id="password"
            label="Password"
            fieldType="input"
            control={control}
            type="password"
            data-testid={signinSelectors.password}
          />
          <LoadingButton
            variant="contained"
            fullWidth
            disabled={isLoading}
            loading={isLoading}
            sx={{ fontSize: 18 }}
            onClick={handleSubmit(handleFormSubmit)}
            data-testid={signinSelectors.submitBtn}
          >
            Log In
          </LoadingButton>

          <Button onClick={handleForgotPassword}>Forgot your password?</Button>

          {!!error && <Alert severity="error">{error}</Alert>}

          <Divider sx={{ color: secondary[70], fontSize: 12 }}>OR</Divider>

          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            sx={{ fontSize: 18, borderColor: secondary[130], color: secondary[130] }}
            startIcon={<LogoGoogle />}
            disabled={isLoading}
            onClick={handleSignInWithGoogle}
          >
            Sign in with Google
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
