import { yupResolver } from "@hookform/resolvers/yup";
import { UseFormProps, UseFormReturn, useForm as useRHForm } from "react-hook-form";
import * as Yup from "yup";

const useForm = <T extends Yup.AnyObjectSchema>(
  schema: T,
  useFormProps?: UseFormProps<Yup.Asserts<T>>
): UseFormReturn<Yup.Asserts<T>> => {
  return useRHForm({ ...useFormProps, resolver: yupResolver(schema) });
};

export default useForm;
