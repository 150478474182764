import { keyframes } from "@emotion/react";

import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";

const pulse = keyframes`
  100% {
    box-shadow: rgba(121, 203,134, 0) 0px 0px 0px 14px;
  }
`;

export const SImage = styled("img")`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 16px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 400px;
    opacity: 15%;
  }
`;

export const STitle = styled(Typography)`
  line-height: 1.1;
  font-size: 31px;
  font-weight: 500;

  span {
    font-weight: bold;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 65px;
    line-height: 65px;
  }
`;

export const SDescription = styled(Typography)`
  font-size: 10px;
  color: #4b3c32;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const SButton = styled(Button)`
  font-size: 16px;
  background: #79cb86;
  box-shadow: rgba(121, 203, 134, 1) 0px 0px 0px 0px;
  animation: 2s ease 0s infinite normal none running ${pulse};

  &:hover {
    background: #6eb67a;
    animation: none;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-weight: 700;
    font-size: 20px;
    padding: 15px 90px;
  }
`;
