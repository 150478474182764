import { TypeAnimation } from "react-type-animation";

import { Container, Stack, Typography } from "@mui/material";

import { SChip, SImage, STitle } from "./styles";

const ThirdFold = () => {
  return (
    <Stack
      position="relative"
      minHeight={{ md: 500, xs: "auto" }}
      justifyContent="center"
      pt={{ xs: "280px", md: 0 }}
    >
      <Container>
        <Stack spacing={2} alignItems="flex-start" width={{ xs: "100%", md: "50%" }}>
          <SChip
            label={
              <TypeAnimation
                sequence={[
                  "Designed for frontline nurses",
                  1000,
                  "Designed for nurse leaders",
                  1000,
                  "Designed for your entire team",
                  1000,
                ]}
                repeat={Infinity}
                preRenderFirstString
                deletionSpeed={50}
              />
            }
          />
          <STitle variant="title">
            Assemble: A personalized experience <span>Just for you. Just enough.</span>
          </STitle>
          <Typography fontSize={{ xs: 16, md: 20 }}>
            Individuals focus on professional development skills specific to their role and practice
            application for seamless integration into daily practice.
          </Typography>
        </Stack>
      </Container>

      <SImage
        src="/images/home/home-pathway-bg-.png"
        alt="Illustration"
        width={877}
        height={575}
        quality={90}
      />
    </Stack>
  );
};

export default ThirdFold;
