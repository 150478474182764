import { toast } from "react-toastify";

import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { copyToClipboard } from "@helpers/share-link";

type ShareLinkProps = {
  url: string;
  onCopy?: () => void;
};

const ShareLink = ({ onCopy, url }: ShareLinkProps) => {
  const handleCopyLink = async () => {
    const { success } = await copyToClipboard(url);

    if (success) {
      onCopy?.();
      toast.success("Link copied!");
    } else {
      toast.error("Error copying link");
    }
  };

  return (
    <TextField
      fullWidth
      disabled
      value={url}
      InputProps={{
        sx: { fontSize: 14 },
        endAdornment: (
          <InputAdornment position="end">
            <Button onClick={handleCopyLink} variant="contained" sx={{ px: 2, py: 1 }}>
              Copy link
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ShareLink;
