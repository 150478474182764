import { SEGMENT_EVENTS } from "@analytics";
import { track } from "@analytics/track";

import { Container, Link, Stack } from "@mui/material";

import LoginPopup, { LoginPopupProps } from "@components/account/Login/Popup";
import AuthUserControl from "@components/layout/AuthUserControl";
import HeaderSkeleton from "@components/layout/Header/Skeleton";

import useAuthSession from "@hooks/useAuthSession";
import usePopup from "@hooks/usePopup";

import { SImage, SLeftColumn, SLoginButton } from "./styles";

const Header = () => {
  const { userId, status, isLoading } = useAuthSession();
  const { handleOpenPopup } = usePopup();

  const handleLogin = () => {
    track(SEGMENT_EVENTS.NAVIGATION.LOGIN_BUTTON_CLICKED);
    handleOpenPopup<LoginPopupProps>({ component: LoginPopup });
  };

  const navbarContent = () => {
    if (isLoading) {
      return <HeaderSkeleton />;
    }

    if (!userId) {
      return (
        <SLoginButton variant="contained" onClick={handleLogin}>
          Login
        </SLoginButton>
      );
    }

    if (status === "finished") {
      return <AuthUserControl />;
    }

    return (
      <SLoginButton variant="contained" component={Link} href="/dashboard">
        Access Assemble here
      </SLoginButton>
    );
  };

  return (
    <Stack>
      <Container>
        <Stack direction="row" justifyContent="space-between" spacing={2} py={2.5}>
          <SLeftColumn>
            <SImage
              src="/images/come-assemble-logo.svg"
              priority
              alt="Come Assemble Logo"
              width={155}
              height={31}
            />
          </SLeftColumn>
          {navbarContent()}
        </Stack>
      </Container>
    </Stack>
  );
};

export default Header;
