import Popup from "@components/common/Popup";

import { LoginComponent } from ".";

export type LoginPopupProps = {
  onClose?: () => void;
  showIcon?: boolean;
};

const LoginPopup = ({ onClose, showIcon }: LoginPopupProps) => {
  return (
    <Popup onClose={onClose} width="450px" closeIcon={showIcon}>
      <LoginComponent onSuccess={onClose} />
    </Popup>
  );
};

export default LoginPopup;
