import { Stack } from "@mui/material";

import Header from "@components/Homepage/Header";
import FirstFold from "@components/Homepage/firstFold";
import FourthFold from "@components/Homepage/fourthFold";
import SecondFold from "@components/Homepage/secondFold";
import ThirdFold from "@components/Homepage/thirdFold";
import PageWrapper from "@components/common/PageWrapper";
import Layout from "@components/layout/Layout";

import { assemble } from "@configs/assemble";

const Homepage = () => {
  return (
    <PageWrapper title={assemble.pageTitle}>
      <Stack spacing={{ xs: 0, md: 8 }}>
        <Header />
        <Stack component="main" spacing={{ xs: 6, md: 10 }}>
          <FirstFold />
          <SecondFold />
          <ThirdFold />
          <FourthFold />
        </Stack>
      </Stack>
    </PageWrapper>
  );
};

export default Homepage;

Homepage.getLayout = function getLayout(page: JSX.Element) {
  return (
    <Layout disableGutters showHeader={false}>
      {page}
    </Layout>
  );
};
