import ShareLink from "@components/common/ShareLink";

import { SSubtitle } from "./styles";

const COPY_LINK_PROMPT = "Copy and paste the link below in your browser to join Assemble!";

export const CopyLinkPrompt = () => {
  const url = global.location?.href;
  return (
    <>
      <SSubtitle>{COPY_LINK_PROMPT}</SSubtitle>
      <ShareLink url={url} />
    </>
  );
};
